/* RESPONSIBLE TEAM: team-growth-opportunities */

import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';

export default class EarlyStageProgramData extends Fragment {
  @attr('number') declare year: number;
  @attr('date') declare startsAt: Date;
  @attr('date') declare endsAt: Date;
  @attr('number') declare discount: number;
}
